<template>
  <div class="container">
    <header>
      <div class="head_content clearfix">
        <img
          data-wow-duration="1.5s"
          data-wow-delay=".5s"
          src="@/assets/logo.png"
          class="head_logo wow animate__fadeIn"
        />
        <ul
          class="head_nav wow animate__fadeIn"
          data-wow-duration="1.5s"
          data-wow-delay=".5s"
        >
          <li
            data-index="0"
            @click="changenav(0)"
            :class="active == 0 ? 'active' : ''"
          >
            <a>首页</a>
          </li>
          <li
            data-index="1"
            @click="changenav(1)"
            :class="active == 1 ? 'active' : ''"
          >
            <a>产品介绍</a>
          </li>
          <li
            data-index="2"
            @click="changenav(2)"
            :class="active == 2 ? 'active' : ''"
          >
            <a>合作伙伴</a>
          </li>
          <li
            data-index="3"
            @click="changenav(3)"
            :class="active == 3 ? 'active' : ''"
            style="margin-right: 0"
          >
            <a>联系我们</a>
          </li>
          <div
            :class="
              active == 1 ? 'active_block article_block_yel' : 'active_block'
            "
          ></div>
        </ul>
      </div>
    </header>
    <!-- 主体内容 通过控制z-index进行切换显示 参考soul -->
    <div :class="pageindex == 0 ? 'firstmain active' : 'firstmain'">
      <div class="first_bg">
        <div class="first_content">
          <img
            object-fit="contain"
            :class="pageindex == 0 ? 'p1_icon1 icon_ani' : 'showani'"
            src="../assets/left_icon1.png"
            alt=""
          />
          <img
            :class="pageindex == 0 ? 'p1_icon2 icon_ani' : 'showani'"
            src="../assets/right_icon1.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="
              pageindex == 0 ? 'p1_star rotate_right icon_ani' : 'icon_ani'
            "
            src="../assets/star.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="
              pageindex == 0
                ? 'p1_downicon rotate_right_bottom icon_ani'
                : 'icon_ani'
            "
            src="../assets/downicon.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="pageindex == 0 ? 'p1_cold cold_scale icon_ani' : 'icon_ani'"
            src="../assets/cold.png"
            alt=""
          />

          <img
            object-fit="contain"
            :class="
              pageindex == 0
                ? 'p1_topicon rotate_right_top icon_ani'
                : 'icon_ani'
            "
            src="../assets/topicon.png"
            alt=""
          />
          <div class="p1_box1">
            <div class="p1_box2">
              <img
                object-fit="contain"
                :class="pageindex == 0 ? 'first_phone showani' : 'showani'"
                src="../assets/p1_phone.png"
                alt=""
              />
            </div>
            <div class="p1_box3">
              <img
                object-fit="contain"
                :class="pageindex == 0 ? 'first_slogan showani' : 'showani'"
                src="../assets/p1-slogan.png"
                alt=""
              />
              <img
                object-fit="contain"
                :class="pageindex == 0 ? 'p1_text showani' : 'showani'"
                src="../assets/p1_text.png"
                alt=""
              />
              <img
                object-fit="contain"
                :class="pageindex == 0 ? 'p1_code showani' : 'showani'"
                src="../assets/p1_code.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <img
        object-fit="contain"
        :class="pageindex == 0 ? 'foot_small_left icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 0 ? 'foot_big_right icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 0 ? 'foot_rocket_left icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 0 ? 'foot_dimon_right icon_ani' : 'icon_ani'"
        src="../assets/dimon.png"
        alt=""
      />
    </div>
    <div :class="pageindex == 1 ? 'firstmain active' : 'firstmain'">
      <div :class="pageindex == 1 ? 'p2_left_box showani' : 'showani'">
        <img
          object-fit="contain"
          class="p2_title"
          src="../assets/p2_title.png"
          alt=""
        />
        <img
          object-fit="contain"
          class="p2_text"
          src="../assets/p2_text.png"
          alt=""
        />
        <div class="p2_span">&nbsp;</div>
        <img
          object-fit="contain"
          class="p2_text2"
          src="../assets/p2_text2.png"
          alt=""
        />
      </div>
      <div :class="pageindex == 1 ? 'p2_right_box showani' : 'showani'">
        <div class="p2_right_content">
          <img
            object-fit="contain"
            src="../assets/p2_phone.png"
            class="p2_right_phone"
          />
          <img
            object-fit="contain"
            src="../assets/p2_img.png"
            class="p2_right_img1"
          />
          <img
            object-fit="contain"
            src="../assets/p2_img2.png"
            class="p2_right_img2"
          />
        </div>
      </div>
      <img
        object-fit="contain"
        :class="pageindex == 1 ? 'p2_cold cold_scale icon_ani' : 'icon_ani'"
        src="../assets/cold.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 1 ? 'p2_star rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="
          pageindex == 1
            ? 'p2_downicon rotate_right_bottom icon_ani'
            : 'icon_ani'
        "
        src="../assets/downicon.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 1 ? 'p2_star2 rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 1 ? 'foot_small_left2 icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 1 ? 'foot_big_right icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 1 ? 'foot_rocket_left2 icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 1 ? 'foot_bluedimon_left icon_ani' : 'icon_ani'"
        src="../assets/bluedimon.png"
        alt=""
      />
    </div>
    <div :class="pageindex == 2 ? 'firstmain active' : 'firstmain'">
      <div :class="pageindex == 2 ? 'p3_left_box showani' : 'showani'">
        <div class="p3_left_content">
          <img
            object-fit="contain"
            src="../assets/p3_phone.png"
            class="p3_left_phone"
          />
          <img
            object-fit="contain"
            src="../assets/p3_img1.png"
            class="p3_left_img1"
          />
        </div>
      </div>
      <div :class="pageindex == 2 ? 'p3_right_box showani' : 'showani'">
        <img
          object-fit="contain"
          src="../assets/p3_title.png"
          class="p3_right_title"
          alt=""
        />
        <img
          object-fit="contain"
          src="../assets/p3_sub.png"
          class="p3_right_sub"
          alt=""
        />
        <div class="p3_right_span">&nbsp;</div>
        <img
          object-fit="contain"
          src="../assets/p3_text.png"
          class="p3_right_text"
          alt=""
        />
      </div>
      <img
        object-fit="contain"
        :class="
          pageindex == 2
            ? 'p3_downicon rotate_right_bottom icon_ani'
            : 'icon_ani'
        "
        src="../assets/downicon.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 2 ? 'p3_cold cold_scale icon_ani' : 'icon_ani'"
        src="../assets/cold.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 2 ? 'p3_star rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 2 ? 'p3_star2 rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="
          pageindex == 2 ? 'p3_topicon rotate_right_top icon_ani' : 'icon_ani'
        "
        src="../assets/topicon.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 2 ? 'foot_small_left icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 2 ? 'foot_big_right3 icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 2 ? 'foot_rocket_left icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 2 ? 'foot_dimon_right icon_ani' : 'icon_ani'"
        src="../assets/dimon.png"
        alt=""
      />
    </div>
    <div :class="pageindex == 3 ? 'firstmain active' : 'firstmain'">
      <div :class="pageindex == 3 ? 'p4_left_box showani' : 'showani'">
        <img
          object-fit="contain"
          src="../assets/p4_title.png"
          class="p4_title"
          alt=""
        />
        <img
          object-fit="contain"
          src="../assets/p4_sub.png"
          class="p4_sub"
          alt=""
        />
        <div class="p4_span">&nbsp;</div>
        <img
          object-fit="contain"
          src="../assets/p4_text.png"
          class="p4_text"
          alt=""
        />
      </div>
      <img
        object-fit="contain"
        src="../assets/p4_phone.png"
        :class="pageindex == 3 ? 'p4_right_box showani' : 'showani'"
      />
      <img
        object-fit="contain"
        :class="
          pageindex == 3
            ? 'p4_downicon rotate_right_bottom icon_ani'
            : 'icon_ani'
        "
        src="../assets/downicon.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 3 ? 'p4_cold cold_scale icon_ani' : 'icon_ani'"
        src="../assets/cold.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 3 ? 'p4_star rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 3 ? 'p4_star2 rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 3 ? 'foot_small_left2 icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 3 ? 'foot_big_right icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 3 ? 'foot_rocket_left2 icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 3 ? 'foot_bluedimon_left icon_ani' : 'icon_ani'"
        src="../assets/bluedimon.png"
        alt=""
      />
    </div>
    <div :class="pageindex == 4 ? 'firstmain active' : 'firstmain'">
      <img
        object-fit="contain"
        src="../assets/p5_phone.png"
        :class="pageindex == 4 ? 'p5_left_box showani' : 'showani'"
      />
      <div :class="pageindex == 4 ? 'p5_right_box showani' : 'showani'">
        <img
          object-fit="contain"
          src="../assets/p5_title.png"
          class="p5_right_title"
          alt=""
        />
        <img
          object-fit="contain"
          src="../assets/p5_sub.png"
          class="p5_right_sub"
          alt=""
        />
        <div class="p5_right_span">&nbsp;</div>
        <img
          object-fit="contain"
          src="../assets/p5_text.png"
          class="p5_right_text"
          alt=""
        />
      </div>
      <img
        object-fit="contain"
        :class="
          pageindex == 4
            ? 'p5_downicon rotate_right_bottom icon_ani'
            : 'icon_ani'
        "
        src="../assets/downicon.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 4 ? 'p5_star rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 4 ? 'p5_cold cold_scale icon_ani' : 'icon_ani'"
        src="../assets/cold.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 4 ? 'p5_star2 rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="
          pageindex == 4 ? 'p5_topicon rotate_right_top icon_ani' : 'icon_ani'
        "
        src="../assets/topicon.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 4 ? 'foot_small_left icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 4 ? 'foot_big_right3 icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 4 ? 'foot_rocket_left icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 4 ? 'foot_dimon_right icon_ani' : 'icon_ani'"
        src="../assets/dimon.png"
        alt=""
      />
    </div>
    <div :class="pageindex == 5 ? 'firstmain active' : 'firstmain'">
      <div :class="pageindex == 5 ? 'p6_left_box showani' : 'showani'">
        <img
          object-fit="contain"
          src="../assets/p6_title.png"
          class="p6_title"
          alt=""
        />
        <img
          object-fit="contain"
          src="../assets/p6_sub.png"
          class="p6_sub"
          alt=""
        />
        <div class="p6_span">&nbsp;</div>
        <img
          object-fit="contain"
          src="../assets/p6_text.png"
          class="p6_text"
          alt=""
        />
      </div>
      <img
        object-fit="contain"
        :class="
          pageindex == 5
            ? 'p5_downicon rotate_right_bottom icon_ani'
            : 'icon_ani'
        "
        src="../assets/downicon.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 5 ? 'p6_star rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 5 ? 'p5_cold cold_scale icon_ani' : 'icon_ani'"
        src="../assets/cold.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 5 ? 'p5_star2 rotate_right icon_ani' : 'icon_ani'"
        src="../assets/star.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="
          pageindex == 5 ? 'p6_topicon rotate_right_top icon_ani' : 'icon_ani'
        "
        src="../assets/topicon.png"
        alt=""
      />

      <div :class="pageindex == 5 ? 'p6_right_box showani' : 'showani'">
        <div class="p6_right_content">
          <img
            object-fit="contain"
            src="../assets/p6_phone.png"
            class="p6_right_phone"
          />
          <img
            object-fit="contain"
            src="../assets/p6_img1.png"
            class="p6_right_img1"
          />
          <img
            object-fit="contain"
            src="../assets/p6_img2.png"
            class="p6_right_img2"
          />
        </div>
      </div>
      <img
        object-fit="contain"
        :class="pageindex == 5 ? 'foot_small_left2 icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 5 ? 'foot_big_right icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 5 ? 'foot_rocket_left2 icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 5 ? 'foot_bluedimon_left icon_ani' : 'icon_ani'"
        src="../assets/bluedimon.png"
        alt=""
      />
    </div>
    <div :class="pageindex == 6 ? 'firstmain active' : 'firstmain'">
      <div class="first_bg">
        <div class="six_content">
          <img
            object-fit="contain"
            :class="pageindex == 6 ? 'p7_logo showani' : 'showani'"
            src="../assets/p7-logo.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="pageindex == 6 ? 'p7_img showani' : 'showani'"
            src="../assets/p7_img2.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="pageindex == 6 ? 'p1_icon1 icon_ani' : 'showani'"
            src="../assets/left_icon1.png"
            alt=""
          />
          <img
            :class="pageindex == 6 ? 'p1_icon2 icon_ani' : 'showani'"
            src="../assets/right_icon1.png"
            alt=""
          />
        </div>
      </div>
      <img
        object-fit="contain"
        :class="pageindex == 6 ? 'foot_small_left icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 6 ? 'foot_big_right3 icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 6 ? 'foot_rocket_left icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 6 ? 'foot_dimon_right icon_ani' : 'icon_ani'"
        src="../assets/dimon.png"
        alt=""
      />
    </div>
    <div :class="pageindex == 7 ? 'firstmain active' : 'firstmain'">
      <div class="first_bg">
        <div class="seven_content">
          <div :class="pageindex == 7 ? 'p8_left_box showani' : 'showani'">
            <img
              object-fit="contain"
              class="p8_title"
              src="../assets/p8_title.png"
              alt=""
            />
            <img
              object-fit="contain"
              class="p8_email"
              src="../assets/p8_email.png"
              alt=""
            />
            <img
              object-fit="contain"
              class="p8_phone"
              src="../assets/p8_phone.png"
              alt=""
            />
            <img
              object-fit="contain"
              class="p8_wx"
              src="../assets/p8_wx.png"
              alt=""
            />
            <img
              object-fit="contain"
              class="p8_weibo"
              src="../assets/p8_weibo.png"
              alt=""
            />
            <img
              object-fit="contain"
              class="p8_redbook"
              src="../assets/p8_redbook.png"
              alt=""
            />
          </div>
          <div :class="pageindex == 7 ? 'p8_right_box showani' : 'showani'">
            <img
              object-fit="contain"
              data-wow-duration="1.5s"
              data-wow-delay=".5s"
              :class="pageindex == 7 ? 'first_slogan showani' : 'showani'"
              src="../assets/p1-slogan.png"
              alt=""
            />
            <img
              object-fit="contain"
              data-wow-duration="1.5s"
              data-wow-delay=".5s"
              :class="pageindex == 7 ? 'p1_text showani' : 'showani'"
              src="../assets/p1_text.png"
              alt=""
            />
            <img
              object-fit="contain"
              data-wow-duration="1.5s"
              data-wow-delay=".5s"
              :class="pageindex == 7 ? 'p1_code showani' : 'showani'"
              src="../assets/p1_code.png"
              alt=""
            />
          </div>
          <div class="p8_line"></div>
          <img
            object-fit="contain"
            :class="pageindex == 7 ? 'p1_icon1 icon_ani' : 'showani'"
            src="../assets/left_icon1.png"
            alt=""
          />
          <img
            :class="pageindex == 7 ? 'p1_icon2 icon_ani' : 'showani'"
            src="../assets/right_icon1.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="
              pageindex == 7
                ? 'p1_downicon rotate_right_bottom icon_ani'
                : 'icon_ani'
            "
            src="../assets/downicon.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="pageindex == 7 ? 'p1_cold cold_scale icon_ani' : 'icon_ani'"
            src="../assets/cold.png"
            alt=""
          />
          <img
            object-fit="contain"
            :class="
              pageindex == 7
                ? 'p1_topicon rotate_right_top icon_ani'
                : 'icon_ani'
            "
            src="../assets/topicon.png"
            alt=""
          />
        </div>
      </div>
      <img
        object-fit="contain"
        :class="pageindex == 7 ? 'foot_small_left icon_ani' : 'icon_ani'"
        src="../assets/left_small.png"
        style="bottom: 1.6665%"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 7 ? 'foot_big_right icon_ani' : 'icon_ani'"
        src="../assets/right_big.png"
        style="bottom: 1.6665%"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 7 ? 'foot_rocket_left icon_ani' : 'icon_ani'"
        src="../assets/rocket.png"
        style="bottom: -5.5556%"
        alt=""
      />
      <img
        object-fit="contain"
        :class="pageindex == 7 ? 'foot_dimon_right icon_ani' : 'icon_ani'"
        src="../assets/dimon.png"
        style="bottom: -1.4808%"
        alt=""
      />
      <div class="p8_bottom_box">
        <p class="p8_bottom_content">
          ©2018
          聚否互联网络科技(成都)有限公司&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a
            target="_blank"
            href="https://beian.miit.gov.cn/"
            >蜀ICP备18028497号-4</a
          >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<img
            class="p8_beian"
            src="../assets/p8_beian.png"
          /><a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010802000692"
            >川公网安备51010802000692号</a
          >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;联系我们：028-69215179
        </p>
      </div>
    </div>
    <div
      :class="
        pageindex == 0 || pageindex == 6 || pageindex == 7
          ? 'bg_mask yel_bg'
          : 'bg_mask'
      "
    >
      <div class="img_bg"></div>
      <div
        v-if="pageindex != 0 && pageindex != 6 && pageindex != 7"
        class="svg_box"
      >
        <svg
          class="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160,35.6 c 30,0,58-6.6,88-6.6 s58,6.6,88,6.6s58-6.6,88-6.6s58,6.6,88,6.6V59h-352V35.6z"
            />
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="#fffbeb" />
            <use xlink:href="#gentle-wave" x="48" y="0" fill="#ffcc22" />
          </g>
        </svg>
      </div>
    </div>
    <!-- 右侧滚动条 -->
    <div class="swiper_box">
      <div
        v-for="(item, index) in 8"
        @click="changeswiper(index)"
        :key="index"
        :class="
          index == pageindex
            ? pageindex == 0 || pageindex == 6 || pageindex == 7
              ? 'swiper_item swiper_active_yel'
              : 'swiper_item swiper_active_white'
            : pageindex == 0 || pageindex == 6 || pageindex == 7
            ? 'swiper_item swiper_bg_yel'
            : 'swiper_item swiper_bg_white'
        "
      ></div>
    </div>
  </div>
</template>

<script>
let timer = null;
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      active: 0, //
      pageindex: 0,
    };
  },
  mounted() {
    window.addEventListener("mousewheel", this.handleScroll);
    window.addEventListener("DOMMouseScroll", this.handleScroll); // firefox滚轮事件兼容
  },
  methods: {
    changenav: function (e) {
      this.changeactive(e);
      let index = e == 0 ? 0 : e == 1 ? 1 : e == 2 ? 6 : 7;
      this.changepage(index);
    },
    changeactive: function (e) {
      if (e == this.active) return;
      this.active = e;
    },
    changepage: function (index) {
      if (index == this.pageindex) return;
      this.pageindex = index;
    },
    changeswiper: function (index) {
      let temp = index;
      let active =
        temp > 0 && temp < 6
          ? 1
          : temp == 0
          ? 0
          : temp == 6
          ? 2
          : temp == 7
          ? 3
          : "";

      this.changeactive(active);
      this.changepage(index);
    },
    handleScroll: function (e) {
      let _this = this,
        nowIndex = this.pageindex;
      if (!timer) {
        timer = setTimeout(() => {
          console.log(e);
          let dis = e.deltaY||e.detail; //firefox滚轮事件兼容
          if (dis > 0) {
            if (nowIndex != 7) {
              _this.pageindex = nowIndex + 1;
              // 0  1          2 3
              // 0, 1,2,3,4,5 ,6,7
              let temp = nowIndex + 1;
              let active =
                temp > 0 && temp < 6
                  ? 1
                  : temp == 0
                  ? 0
                  : temp == 6
                  ? 2
                  : temp == 7
                  ? 3
                  : "";
              // console.log(active)
              _this.changeactive(active);
            }
          } else {
            if (nowIndex != 0) {
              _this.pageindex = nowIndex - 1;
              let temp = nowIndex - 1;
              let active =
                temp > 0 && temp < 6
                  ? 1
                  : temp == 0
                  ? 0
                  : temp == 6
                  ? 2
                  : temp == 7
                  ? 3
                  : "";
              _this.changeactive(active);
            }
          }
          timer = null;
        }, 500);
      }
    },
  },
};
</script>
<style lang="scss">
.waves {
  width: 100%;
  height: 100%;
  margin-bottom: -7px;
}
.parallax > use:nth-child(1) {
  animation: move-forever 7s linear infinite;
  animation-delay: -2s;
  animation-duration: 7s;
  animation-fill-mode: none;
  // animation-fill-mode: both;
}
.parallax > use:nth-child(2) {
  animation: move-forever 12s linear infinite;
  animation-delay: -3s;
  animation-duration: 12s;
  animation-fill-mode: none;
  // animation-fill-mode: both;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@-webkit-keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
a {
  text-decoration: none;
}

.active {
  z-index: 20;
}
// .noactive{
//   z-index:1
// }
.foot_small_left {
  // width: 801px;
  height: 19.537%;
  position: fixed;
  left: 0;
  bottom: -4.6296%;
  z-index: 100;
  // scale: .9;
  animation-name: foot_small_leftani;
}
.foot_small_left2 {
  // width: 801px;
  height: 32.537%;
  position: fixed;
  left: 0;
  bottom: 0;
  bottom: -8.6296%;
  z-index: 100;
  // scale: .9;
  animation-name: foot_small_leftani;
}
.foot_big_right {
  // width: 806px;
  height: 19.7222%;
  position: fixed;
  right: 0;
  bottom: -4.6296%;
  z-index: 100;
  animation-name: foot_big_rightani;
}
.foot_big_right3 {
  // width: 806px;
  height: 28.7222%;
  position: fixed;
  right: 0;
  bottom: -7.6296%;
  z-index: 100;
  animation-name: foot_big_rightani;
}
.foot_rocket_left {
  height: 9.074%;
  position: fixed;
  left: 5.208%;
  bottom: -11.8518%;
  z-index: 100;
  animation-name: foot_rocket_leftani;
}
.foot_rocket_left2 {
  height: 11.074%;
  position: fixed;
  left: 1.208%;
  bottom: -11.8518%;
  z-index: 100;
  animation-name: foot_rocket_leftani;
}
.foot_dimon_right {
  height: 7.777%;
  position: fixed;
  right: 5.3479%;
  bottom: -9.2592%;
  z-index: 100;
  animation-name: foot_dimon_leftani;
}
.foot_dimon_right3 {
  height: 7.777%;
  position: fixed;
  right: 7.3479%;
  bottom: -9.2592%;
  z-index: 100;
  animation-name: foot_dimon_leftani;
}
.foot_bluedimon_left {
  height: 7.777%;
  position: fixed;
  left: 16.3479%;
  bottom: -7.2592%;
  z-index: 100;
  animation-name: foot_bluedimon_leftani;
}
.bg_mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 19;
  background-color: #fff;
  .svg_box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 37.5%;
    z-index: 19;
  }
}
.container {
  width: 100%;
  height: 100%;
}
.yel_bg {
  background-color: #ffcc22;
}
.clearfix {
  content: "";
  display: block;
  clear: both;
}
.img_bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:19;
  background: url("../assets/bg_img.png");
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: cover;
}
.firstmain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: url("../assets/bg_img.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  // -moz-background-size: cover;
  .first_bg {
    width: 90%;
    height: 89%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    border-radius: 80px;
    overflow: hidden;
    transform: translateX(-50%);
    .first_content {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .p1_box1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 83.15%;
        width: 100%;
        .p1_box2 {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .first_phone {
            height: 100%;
            animation-name: show_frame1;
          }
        }
        .p1_box3 {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // margin-right: 10.3948%;
          overflow: auto;
          .first_slogan {
            height: 42.5157%;
            margin-bottom: 2.1385%;
            animation-name: show_frame1;
          }
          .p1_text {
            // width: 534px;
            height: 14.2138%;
            margin-bottom: 8.8059%;
            animation-name: show_frame1;
          }
          .p1_code {
            // width: 357px;
            height: 16.7295%;
            animation-name: show_frame1;
            // position: absolute;
            // right: 289px;
            // bottom: 164px;
          }
        }
      }

      .p1_icon1 {
        position: absolute;
        left: -0.58%;
        top: -2.186%;
        // width: 120px;
        height: 12.5523%;
        animation-name: p1_icon1ani;
      }
      .p1_icon2 {
        position: absolute;
        right: -20px;
        top: -20px;
        width: 233px;
        height: 233px;
        animation-name: p1_icon2ani;
        transform:scale(0.9)
      }
      .p1_star {
        position: absolute;
        left: 12.9069%;
        top: 9.5188%;
        height: 5.3347%;
        transform: rotate(15deg);
        animation-name: star_left;
      }

      .p1_downicon {
        position: absolute;
        left: 50%;
        top: 15.5857%;
        height: 6.9037%;
        animation-name: img_right_bottom;
        // scale: 0.9;
      }
      .p1_cold {
        position: absolute;
        left: 54.4767%;
        top: 5.2301%;
        height: 8.682%;
        animation-name: code_ani;
        // scale: 0.9;
      }
      .p1_topicon {
        position: absolute;
        right: 3.4883%;
        top: 56.0669%;
        height: 8.2635%;
        animation-name: top_ani;
        // scale: 0.9;
      }
    }
    .six_content {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .p7_logo {
        height: 67.364%;
        animation-name: show_frame1;
      }
      .p7_img {
        position: absolute;
        left: 28.4883%;
        bottom: 6.9037%;
        // width: 120px;
        height: 7.8451%;
        animation-name: show_frame1;
      }
      .p1_icon1 {
        position: absolute;
        left: -0.58%;
        top: -2.186%;
        // width: 120px;
        height: 12.5523%;
        animation-name: p1_icon1ani;
      }
      .p1_icon2 {
        position: absolute;
        right: -20px;
        top: -20px;
        width: 233px;
        height: 233px;
        animation-name: p1_icon2ani;
        transform:scale(0.9)
      }
    }
    .seven_content {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .p8_left_box {
        width: 27.468%;
        height: 66.9456%;
        position: absolute;
        left: 9.001%;
        top: 50%;
        transform: translateY(-50%) ;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        animation-name: show_frame;
        .p8_title {
          height: 8.75%;
          margin-bottom: 7.5%;
        }
        .p8_email {
          height: 7.6562%;
          margin-bottom: 7.5%;
        }
        .p8_phone {
          height: 7.6562%;
          margin-bottom: 20%;
        }
        .p8_wx {
          height: 7.6562%;
          margin-bottom: 7.5%;
        }
        .p8_weibo {
          height: 7.6562%;
          margin-bottom: 7.5%;
        }
        .p8_redbook {
          height: 7.6562%;
          margin-bottom: 7.5%;
        }
      }
      .p8_line {
        width: 0;
        height: 63.3891%;
        position: absolute;
        left: 46.2253%;
        top: 50%;
        transform: translateY(-50%);
        border-left: 1px solid rgba(112, 112, 112, 0.23);
        margin-top: 1.1506%;
      }
      .p8_right_box {
        position: absolute;
        right: 10.3368%;
        top: 0;
        width: 33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation-name: show_frame1;
        // margin-right: 10.3948%;
        overflow: auto;
        .first_slogan {
          height: 35.3556%;
          margin-bottom: 1.7782%;
          
        }
        .p1_text {
          // width: 534px;
          height: 11.82%;
          margin-bottom: 7.3221%;
          animation-name: show_frame1;
        }
        .p1_code {
          // width: 357px;
          height: 13.9121%;
          animation-name: show_frame1;
          // position: absolute;
          // right: 289px;
          // bottom: 164px;
        }
      }
      .p1_icon1 {
        position: absolute;
        left: -0.58%;
        top: -2.186%;
        // width: 120px;
        height: 12.5523%;
        animation-name: p1_icon1ani;
      }
      .p1_icon2 {
        position: absolute;
        right: -20px;
        top: -20px;
        width: 233px;
        height: 233px;
        animation-name: p1_icon2ani;
        transform:scale(0.9)
      }
      .p1_downicon {
        position: absolute;
        left: 50%;
        top: 15.5857%;
        height: 6.9037%;
        animation-name: img_right_bottom;
      }
      .p1_cold {
        position: absolute;
        left: 54.4767%;
        top: 5.2301%;
        height: 8.682%;
        animation-name: code_ani;
      }
      .p1_topicon {
        position: absolute;
        right: 3.4883%;
        top: 56.0669%;
        height: 8.2635%;
        animation-name: top_ani;
      }
    }
  }
  .p2_left_box {
    position: absolute;
    top: 50%;
    left: 7.2916%;
    width: 32.7083%;
    height: 53.9814%;
    transform: translateY(-50%) scale(0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    animation-name: show_frame;
    .p2_title {
      height: 46.9982%;
    }
    .p2_text {
      height: 12.1783%;
    }
    .p2_span {
      width: 12.2611%;
      height: 1.8867%;
      background-color: #ffce1e;
      margin: 6.6895% 0 6.1749%;
    }
    .p2_text2 {
      height: 29.1595%;
    }
  }
  .p2_right_box {
    position: absolute;
    top: 50%;
    right: 0;
    // width: 32.7083%;
    height: 75.7407%;
    transform: translateY(-50%) scale(0.8);
    margin-top: 2.9629%;
    animation-name: show_frame;
    .p2_right_content {
      position: relative;
      height: 100%;
      .p2_right_phone {
        height: 100%;
      }
      .p2_right_img1 {
        position: absolute;
        left: 12.7952%;
        top: 19.1931%;
        height: 11.7359%;
      }
      .p2_right_img2 {
        position: absolute;
        left: 12.7952%;
        top: 73.2273%;
        height: 20.2933%;
      }
    }
  }
  .p3_left_box {
    position: absolute;
    top: 50%;
    left: 4.427%;
    height: 75.6481%;
    transform: translateY(-50%) scale(0.8);
    margin-top: 5.0925%;
    animation-name: show_frame;
    .p3_left_content {
      position: relative;
      height: 100%;
      .p3_left_phone {
        height: 100%;
      }
      .p3_left_img1 {
        position: absolute;
        left: 18.273%;
        top: 14.0758%;
        height: 23.623%;
      }
    }
  }
  .p3_right_box {
    position: absolute;
    top: 50%;
    right: 8.3333%;
    width: 34.1145%;
    height: 53.4259%;
    transform: translateY(-50%) scale(0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -4.5722%;
    animation-name: show_frame;
    .p3_right_title {
      height: 49.4921%;
    }
    .p3_right_sub {
      height: 12.305%;
    }
    .p3_right_span {
      width: 11.7557%;
      height: 1.9064%;
      margin: 6.6895% 0 6.1749%;
      background-color: #ffce1e;
    }
    .p3_right_text {
      height: 28.4228%;
    }
  }
  .p4_left_box {
    position: absolute;
    top: 50%;
    left: 7.2916%;
    width: 32.7083%;
    height: 66.2962%;
    transform: translateY(-50%) scale(0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -1.666%;
    animation-name: show_frame;
    .p4_title {
      height: 38.2681%;
    }
    .p4_sub {
      height: 9.916%;
    }
    .p4_span {
      width: 12.2611%;
      height: 1.5363%;
      background-color: #ffce1e;
      margin: 6.6895% 0 6.1749%;
    }
    .p4_text {
      height: 42.3184%;
    }
  }
  .p4_right_box {
    position: absolute;
    top: 50%;
    right: 0;
    height: 69.1666%;
    transform: translateY(-50%) scale(0.8);
    animation-name: show_frame;
    // margin-top: 4.3518%;
  }
  .p5_left_box {
    position: absolute;
    top: 50%;
    left: 0.0833%;
    height: 88.5185%;
    transform: translateY(-50%) scale(0.8);
    margin-top: 3.9814;
    animation-name: show_frame;
  }
  .p5_right_box {
    position: absolute;
    top: 50%;
    right: 8.3333%;
    width: 34.1145%;
    height: 71.9444%;
    transform: translateY(-50%) scale(0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4.537%;
    animation-name: show_frame;
    .p5_right_title {
      height: 35.2638%;
    }
    .p5_right_sub {
      height: 9.1377%;
    }
    .p5_right_span {
      width: 11.7557%;
      height: 1.9064%;
      margin: 6.6895% 0 6.1749%;
      background-color: #ffce1e;
    }
    .p5_right_text {
      height: 46.8468%;
    }
  }
  .p6_left_box {
    position: absolute;
    top: 50%;
    left: 7.2916%;
    width: 39.0104%;
    height: 54.7222%;
    transform: translateY(-50%) scale(0.8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -4.074%;
    animation-name: show_frame;
    .p6_title {
      height: 46.362%;
    }
    .p6_sub {
      height: 12.0135%;
    }
    .p6_span {
      width: 10.2803%;
      height: 1.861%;
      background-color: #ffce1e;
      margin: 6.5989% 0 6.0913%;
    }
    .p6_text {
      height: 30.1184%;
    }
  }
  // .p6_right_box{
  //   position: fixed;
  //   top: 11.1111%;
  //   right: 7.8125%;
  //   height: 81.3888%;
  // }
  .p6_right_box {
    position: absolute;
    top: 50%;
    right: 7.8125%;
    // width: 32.7083%;
    height: 81.3888%;
    transform: translateY(-50%) scale(0.8);
    margin-top: 2%;
    animation-name: show_frame;
    .p6_right_content {
      position: relative;
      height: 100%;
      .p6_right_phone {
        height: 100%;
      }
      .p6_right_img1 {
        position: absolute;
        left: 34.0292%;
        top: 7.3947%;
        height: 31.6268%;
      }
      .p6_right_img2 {
        position: absolute;
        right: 14.1962%;
        top: 31.8543%;
        height: 29.9203%;
      }
    }
  }
  .p8_bottom_box {
    width: 100%;
    height: 6.2962%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #040000;
    z-index: 101;

    .p8_bottom_content {
      font-size: 22px;
      color: #c6c6c6;
      line-height: 30px;
      display: flex;
      align-items: center;
      a {
        color: #c6c6c6;
      }
      .p8_beian {
        width: 22px;
        height: 22px;
      }
    }
  }
  .p2_cold {
    position: fixed;
    left: 44.6875%;
    top: 12.6851%;
    height: 7.6851%;
    animation-name: code_ani;
  }
  .p2_star {
    position: fixed;
    left: 38.9583%;
    top: 47.5925%;
    height: 4.7222%;
    transform: rotate(15deg);
    animation-name: star_left;
  }
  .p2_downicon {
    position: fixed;
    left: 45%;
    top: 31.2962%;
    height: 6.1111%;
    animation-name: img_right_bottom;
  }
  .p2_topicon {
    position: fixed;
    left: 46.9791%;
    top: 68.8888%;
    height: 7.3148%;
  }
  .p2_star2 {
    position: fixed;
    right: 8.2812%;
    top: 15.1851%;
    height: 6.4814%;
    transform: rotate(-15deg);
    animation-name: star_right;
  }
  .p3_downicon {
    position: fixed;
    left: 5.9375%;
    top: 21.3888%;
    height: 6.1111%;
    animation-name: img_right_bottom;
  }
  .p3_cold {
    position: fixed;
    left: 46.7187%;
    top: 15.1851%;
    height: 7.6851%;
    animation-name: code_ani;
  }
  .p3_star {
    position: fixed;
    left: 47.2916%;
    top: 47.5925%;
    height: 4.7222%;
    transform: rotate(15deg);
    animation-name: star_left;
  }
  .p3_star2 {
    position: fixed;
    right: 8.3333%;
    top: 15.1851%;
    height: 6.4814%;
    transform: rotate(-15deg);
    animation-name: star_right;
  }
  .p3_topicon {
    position: fixed;
    right: 6.0937%;
    top: 53.1481%;
    height: 7.4074%;
    animation-name: top_ani;
  }
  .p4_downicon {
    position: fixed;
    left: 3.9375%;
    top: 16.3888%;
    height: 6.0111%;
    animation-name: img_right_bottom;
  }
  .p4_cold {
    position: fixed;
    left: 46.7187%;
    top: 15.1851%;
    height: 7.6851%;
    animation-name: code_ani;
  }
  .p4_star {
    position: fixed;
    left: 40.677%;
    top: 43.2407%;
    height: 4.7222%;
    transform: rotate(15deg);
    animation-name: star_left;
  }
  .p4_star2 {
    position: fixed;
    right: 8.3333%;
    top: 15.1851%;
    height: 6.4814%;
    transform: rotate(-15deg);
    animation-name: star_right;
  }
  .p5_downicon {
    position: fixed;
    left: 3.9375%;
    top: 20.3888%;
    height: 6.1111%;
    animation-name: img_right_bottom;
  }
  .p5_star {
    position: fixed;
    left: 44.1145%;
    top: 27.5%;
    height: 4.7222%;
    transform: rotate(15deg);
    animation-name: star_left;
  }
  .p5_cold {
    position: fixed;
    left: 46.7187%;
    top: 15.1851%;
    height: 7.6851%;
    animation-name: code_ani;
  }
  .p5_star2 {
    position: fixed;
    right: 8.3333%;
    top: 15.1851%;
    height: 6.4814%;
    transform: rotate(-15deg);
    animation-name: star_right;
  }
  .p5_topicon {
    position: fixed;
    right: 12.6562%;
    top: 50%;
    height: 7.3148%;
    animation-name: top_ani;
  }
  .p6_star {
    position: fixed;
    left: 45.3645%;
    top: 45.2777%;
    height: 4.7222%;
    transform: rotate(15deg);
    animation-name: star_left;
  }
  .p6_topicon {
    position: fixed;
    right: 8.3333%;
    top: 55.3703%;
    height: 7.4074%;
    animation-name: top_ani;
  }
}
header {
  width: 100%;
  min-width: 800px;
  height: 6%;
  padding: 0 5%;
  position: fixed;
  top: 3%;
  left: 0;
  z-index: 100;
  .head_content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // max-width: 1340px;
    .head_logo {
      width: 181px;
      height: 70px;
    }
    .head_nav {
      // float: right;
      height: 100%;
      list-style: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        list-style: none;
        // float: left;
        margin-right: 102px;
        font-size: 24px;
        line-height: 33px;
        // padding-top: 15px;
        a {
          color: #000;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
        }
      }
      li.active {
        a {
          font-weight: bold;
        }
      }
      li[data-index="0"].active ~ .active_block {
        transform: translateX(0);
      }
      li[data-index="1"].active ~ .active_block {
        transform: translateX(150px);
      }
      li[data-index="2"].active ~ .active_block {
        transform: translateX(350px);
      }
      li[data-index="3"].active ~ .active_block {
        transform: translateX(550px);
      }

      .active_block {
        position: absolute;
        width: 46px;
        height: 19px;
        background-color: #fff;
        bottom: -4px;
        left: -17px;
        z-index: -1;
        border-radius:10px;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .article_block_yel {
        background-color: #ffcf1e;
      }
    }
  }
}
.swiper_box {
  width: 10px;
  height: 17.2322%;
  position: fixed;
  right: 3.9583%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  .swiper_item {
    width: 100%;
    height: 10px;
    transition: all 0.3s linear;
    cursor: pointer;
  }
  .swiper_active_yel {
    height: 32px;
    background-color: #ffffff;
    border-radius: 90px;
  }
  .swiper_active_white {
    height: 32px;
    background-color: #ffcf1e;
    border-radius: 90px;
  }
  .swiper_bg_yel {
    background-color: #ffeca5;
    border-radius: 50%;
  }
  .swiper_bg_white {
    background-color: #cccccc;
    border-radius: 50%;
  }
}

.icon_ani {
  animation-duration: 5s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index:20;
  // transform-origin:50% 0
}
.rotate_right {
  transform-origin: 70% -20%;
}
.rotate_right_bottom {
  transform-origin: -150% 100%;
}
.rotate_right_top {
  transform-origin: -150% 100%;
}
.cold_scale {
  transform-origin: 0% 0%;
}
.showani {
  animation-duration: 1s;
  animation-delay: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 30;
}

@keyframes show_frame {
  0% {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}
@-webkit-keyframes show_frame {
  0% {
    opacity: 0;
    transform: translateY(-50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}
@keyframes show_frame1 {
  0% {
    opacity: 0;
    transform:scale(0.8);
  }
  100% {
    opacity: 1;
    transform:scale(1);
  }
}
@-webkit-keyframes show_frame1 {
  0% {
    opacity: 0;
    transform:scale(0.8);
  }
  100% {
    opacity: 1;
    transform:scale(1);
  }
}
@keyframes star_left {
  0% {
    transform: rotate(15deg);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(-10deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(-10deg);
    scale: 1.1;
  }
  // 78% {
  //   transform: rotate(15deg);
  //   scale: 1;
  // }
  100% {
    transform: rotate(15deg);
    scale: 1;
  }
}
@-webkit-keyframes star_left {
  0% {
    transform: rotate(15deg);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(-10deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(-10deg);
    scale: 1.1;
  }
  100% {
    transform: rotate(15deg);
    scale: 1;
  }
}
@keyframes star_right {
  0% {
    transform: rotate(-15deg);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(10deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(10deg);
    scale: 1.1;
  }
  100% {
    transform: rotate(-15deg);
    scale: 1;
  }
}
@-webkit-keyframes star_right {
  0% {
    transform: rotate(-15deg);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(10deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(10deg);
    scale: 1.1;
  }
  100% {
    transform: rotate(-15deg);
    scale: 1;
  }
}
@keyframes img_right_bottom {
  0% {
    transform: rotate(0);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(15deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(15deg);
    scale: 1.1;
  }
  100% {
    transform: rotate(0);
    scale: 1;
  }
}
@-webkit-keyframes img_right_bottom {
  0% {
    transform: rotate(0);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(15deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(15deg);
    scale: 1.1;
  }
  100% {
    transform: rotate(0);
    scale: 1;
  }
}
@keyframes top_ani {
  0% {
    transform: rotate(0);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(-20deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(-20deg);
    scale: 1.1;
  }
  100% {
    transform: rotate(0);
    scale: 1;
  }
}
@-webkit-keyframes top_ani {
  0% {
    transform: rotate(0);
    scale: 1;
  }
  // 28% {
  //   transform: rotate(-20deg);
  //   scale: 1.1;
  // }
  50% {
    transform: rotate(-20deg);
    scale: 1.1;
  }
  100% {
    transform: rotate(0);
    scale: 1;
  }
}
@keyframes code_ani {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes code_ani {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes p1_icon1ani {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  50% {
    transform: translate3d(8.333%, 8.333%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes p1_icon1ani {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  50% {
    transform: translate3d(8.333%, 8.333%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes p1_icon2ani {
  0% {
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  50% {
    transform: translate3d(-10px, 10px, 0) scale(1);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(.9);
  }
}
@-webkit-keyframes p1_icon2ani {
  0% {
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  50% {
    transform: translate3d(-10px, 10px, 0) scale(1);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(0.9);
  }
}
@keyframes foot_small_leftani {
  0% {
    transform: translate3d(0, 0, 0);
  }
  // 28% {
  //   transform: translate3d(0, -23.4741%, 0);
  // }
  50% {
    transform: translate3d(0, -23.4741%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes foot_small_leftani {
  0% {
    transform: translate3d(0, 0, 0);
  }
  // 28% {
  //   transform: translate3d(0, -23.4741%, 0);
  // }
  50% {
    transform: translate3d(0, -23.4741%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes foot_big_rightani {
  0% {
    transform: translate3d(0, 0, 0);
  }
  // 28% {
  //   transform: translate3d(0, -23.4741%, 0);
  // }
  50% {
    transform: translate3d(0, -23.4741%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes foot_big_rightani {
  0% {
    transform: translate3d(0, 0, 0);
  }
  // 28% {
  //   transform: translate3d(0, -23.4741%, 0);
  // }
  50% {
    transform: translate3d(0, -23.4741%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes foot_rocket_leftani {
  0% {
    transform: translate3d(0, 0, 0);
  }
  // 28% {
  //   transform: translate3d(50.4741%, -120%, 0);
  // }
  50% {
    transform: translate3d(50.4741%, -120%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes foot_rocket_leftani {
  0% {
    transform: translate3d(0, 0, 0);
  }
  // 28% {
  //   transform: translate3d(50.4741%, -120%, 0);
  // }
  50% {
    transform: translate3d(50.4741%, -120%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes foot_dimon_leftani {
  0% {
    transform: translate3d(0, 0, 0);
    rotate: -20deg;
  }
  // 28% {
  //   transform: translate3d(-120.4741%, -80%, 0);
  //   rotate: 20deg;
  // }
  50% {
    transform: translate3d(-120.4741%, -80%, 0);
    rotate: 20deg;
  }
  100% {
    transform: translate3d(0, 0, 0);
    rotate: -20deg;
  }
}
@-webkit-keyframes foot_dimon_leftani {
  0% {
    transform: translate3d(0, 0, 0);
    rotate: -20deg;
  }
  // 28% {
  //   transform: translate3d(-120.4741%, -80%, 0);
  //   rotate: 20deg;
  // }
  50% {
    transform: translate3d(-120.4741%, -80%, 0);
    rotate: 20deg;
  }
  100% {
    transform: translate3d(0, 0, 0);
    rotate: -20deg;
  }
}
@keyframes foot_bluedimon_leftani {
  0% {
    transform: translate3d(0, 0, 0);
    rotate:-20deg;
  }
  // 28% {
  //   transform: translate3d(50%, -150%, 0);
  //   rotate: 20deg;
  // }
  50% {
    transform: translate3d(50%, -150%, 0);
    rotate: 20deg;
  }
  100% {
    transform: translate3d(0, 0, 0);
    rotate: -20deg;
  }
}
@-webkit-keyframes foot_bluedimon_leftani {
  0% {
    transform: translate3d(0, 0, 0);
    rotate:-20deg;
  }
  // 28% {
  //   transform: translate3d(50%, -150%, 0);
  //   rotate: 20deg;
  // }
  50% {
    transform: translate3d(50%, -150%, 0);
    rotate: 20deg;
  }
  100% {
    transform: translate3d(0, 0, 0);
    rotate: -20deg;
  }
}
</style>
