<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  import wow from "wow.js";
  export default {
  data() {
    return {};
  },
  methods: {
    
  },
  mounted() {
    new wow({
      offset: 50,
    }).init();
    // console.log("mounted");
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, STHeiTi, Arial,
    sans-serif;
}
html {
  height: 100%;
  overflow: hidden;
  // font-size: 15px;
  color: #000;
}
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
::selection {
    background: #ffcf1e;
    color: #fff;
    text-shadow: none;
}
</style>
