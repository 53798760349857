import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { detectZoom } from '@/utils/zoom.js';
// const m = detectZoom();
// document.body.style.zoom = 100 / Number(m);
Vue.config.productionTip = false

import 'animate.css'
import 'animate.css/animate.compat.css'

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
